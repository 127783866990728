import { Page } from "@/models";
import { RegistroDeCustos } from "@/models/RegistroDeCustos";
import { AxiosRequestConfig } from "axios";
import { Inject } from "inversify-props"
import type { RegistroDeCustosServiceAdapter } from "./adapter";

export default class FindRegistroDeCustosUseCase {
	@Inject('RegistroDeCustosServiceAdapter')
	private registroDeCustosServiceAdapter!: RegistroDeCustosServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<RegistroDeCustos>> =>
		await this.registroDeCustosServiceAdapter.find(params, config)

	findPrecoDeCusto = async (params?: any): Promise<number> =>
		await this.registroDeCustosServiceAdapter.findPrecoDeCusto(params)
}