import { TipoDeRecurso, TipoDeOperacao } from './../models/Recurso';
import { RawLocation } from 'vue-router'

const itensDeMenu: ItemDeMenu[] = [
	{
		icon: 'mdi-home',
		title: 'Início',
		route: { name: 'Início' },
		exact: true,
		rotaComMenus: true,
	},
	{
		icon: 'mdi-view-dashboard-variant',
		title: 'Dashboards',
		route: { name: 'Dashboards' },
		recurso: 'dashboards',
		operacao: 'ler',
	},
	{
		icon: 'mdi-cart',
		title: 'Caixa',
		route: { name: 'Caixa' },
		recurso: 'vendas',
		operacao: 'criar',
	},
	{
		icon: 'mdi-receipt',
		title: 'Vendas',
		route: {name: 'Lista de Vendas'},
		recurso: 'vendas',
		exact: true,
		children: [
			{
				icon: 'mdi-receipt',
				title: 'Vendas',
				route: {name: 'Lista de Vendas'},
				recurso: 'vendas',
				exact: true,
			},
			{
				icon: 'mdi-receipt-text-edit',
				title: 'Pré-vendas',
				route: {name: 'Lista de pré-vendas'},
				recurso: 'pre-vendas',
			},
			{
				icon: 'mdi-cash-register',
				title: 'Fechamentos',
				route: { name: 'Fechamentos de Caixa' },
				recurso: 'fechamentos-de-caixa',
			},
			{
				icon: 'mdi-ticket-percent',
				title: 'Cupons',
				route: { name: 'Cupons de Desconto' },
				recurso: 'cupons',
			},
			{
				icon: 'mdi-axis-arrow',
				title: 'Painel de Caixas',
				route: { name: 'Painel de Caixa' },
				recurso: 'sangria',
			},
			{
				icon: 'mdi-bullseye-arrow',
				title: 'Metas',
				route: { name: 'Metas' },
				recurso: 'tela-de-metas',
			},
			{
				icon: 'mdi-credit-card-outline',
				title: 'Bandeiras',
				route: { name: 'Bandeira De Cartao' },
				recurso: 'vendas',
			},
			{
				icon: 'mdi-file-document',
				title: 'Pedidos Romaneio',
				route: { name: 'Pedidos Romaneio' },
				recurso: 'pedidos-romaneio',
			},
			{
				icon: 'mdi-alpha-m-circle',
				title: 'Modalidades De Venda',
				route: { name: 'Modalidades De Venda' },
				recurso: 'modalidades-de-venda',
			},
			{
				icon: ' mdi-credit-card-edit',
				title: 'Adquirentes',
				route: { name: 'Adquirente' },
				recurso: 'vendas',
			},
		],
	},
	{
		icon: 'mdi-archive-arrow-up',
		title: 'Pedidos',
		route: {name: 'Pedidos'},
		recurso: 'pedidos',
		exact: true,
		children: [
			{
				icon:'mdi-package-variant-closed',
				title: 'Pedidos',
				route: {name: 'Pedidos'},
				recurso: 'pedidos',
			},
			{
				icon:'mdi-math-log',
				title: 'Logs de Pedidos',
				route: {name: 'Logs de Pedidos'},
				recurso: 'logs-de-pedidos',
			},
			{
				icon:'mdi-file-document-edit',
				title: 'Motivos de Cancelamento',
				route: {name: 'Motivos de Cancelamento'},
				recurso: 'pedidos',
			},
		],
	},
	{
		icon: 'mdi-import',
		title: 'Entradas',
		children: [
			{
				icon: 'mdi-download',
				title: 'Importar Nota',
				route: { name: 'Importacao de Notas' },
				recurso: 'documentos-fiscais',
				operacao: 'criar',
			},
			{
				icon: 'mdi-truck',
				title: 'Fornecedores',
				route: { name: 'Fornecedores' },
				recurso: 'fornecedores',
			},
			{
				icon: 'mdi-transit-connection-variant',
				title: 'Notas em trânsito',
				route: { name: 'Notas em trânsito' },
				recurso: 'notas-em-transito',
			},
		],
	},
	{
		icon: 'mdi-file-document',
		title: 'Fiscal',
		route: { name: 'Configurações Fiscais' },
		recurso: 'regras-imposto',
		children: [
			{
				icon: 'mdi-store-check',
				title: 'Emissão de Notas',
				route: { name: 'Emissão de Notas' },
				recurso: 'emissao-notas',
			},
			{
				icon: 'mdi-list-box',
				title: 'Listagem De Notas',
				route: { name: 'Listagem De Notas' },
				recurso: 'listagem-de-notas',
			},
			{
				icon: 'mdi-receipt',
				title: 'Regras',
				route: { name: 'Configurações Fiscais' },
				recurso: 'regras-imposto',
			},
			{
				icon: 'mdi-archive-cancel',
				title: 'Motivos de Cancelamento fiscal',
				route: { name: 'Motivos de Cancelamento Fiscal' },
				recurso: 'motivos-de-cancelamento-fiscal',
			},
			{
				icon: 'mdi-file-document',
				title: 'Documentos',
				route: { name: 'Documentos Fiscais' },
				recurso: 'documentos-fiscais',
			},
		],
	},
	{
		icon: 'mdi-tag',
		title: 'Produtos',
		route: { name: 'Produtos' },
		recurso: 'produtos',
		children: [
			{
				icon: 'mdi-tag',
				title: 'Produtos',
				route: { name: 'Produtos' },
				recurso: 'produtos',
			},
			{
				icon: 'mdi-currency-usd',
				title: 'Cadastro de preço',
				route: { name: 'Tabelas de Preços' },
				recurso: 'tabela-de-precos',
			},
			{
				icon: 'mdi-database-search',
				title: 'DNA do Produto',
				route: { name: 'DNA do Produto' },
				recurso: 'dna-do-produto',
			},
			{
				icon: 'mdi-flag-variant',
				title: 'Marcas',
				route: { name: 'Marcas' },
				recurso: 'marcas',
			},
			{
				icon: 'mdi-format-line-spacing',
				title: 'Atributos',
				route: { name: 'Atributos' },
				recurso: 'atributos',
			},
			{
				icon: 'mdi-tag-multiple',
				title: 'Tags',
				route: { name: 'Tags' },
				recurso: 'tags',
			},
			{
				icon: 'mdi-sun-snowflake-variant',
				title: 'Estações',
				route: { name: 'Estações' },
				recurso: 'estacao',
			},
			{
				icon: 'mdi-archive-alert',
				title: 'Dashboard',
				route: { name: 'Dashboard de Produtos' },
				recurso: 'situacao-produtos',
			},
			{
				icon: 'mdi-view-grid-plus-outline ',
				title: 'Categorias',
				route: { name: 'Categorias' },
				recurso: 'categoria',
			},
			{
				icon: 'mdi-square-edit-outline ',
				title: 'Alteração em Massa',
				route: { name: 'Alteração em Massa' },
				recurso: 'alteracao-em-massa',
			},
		],
	},
	{
		icon: 'mdi-sticker-check',
		title: 'Etiquetas',
		route: { name: 'Emissão de Etiquetas' },
		recurso: 'etiquetas',
	},
	{
		icon: 'mdi-store',
		title: 'Lojas',
		route: { name: 'Lojas' },
		recurso: 'lojas',
		children: [
			{
				icon: 'mdi-store',
				title: 'Lojas',
				route: { name: 'Lojas' },
				recurso: 'lojas',
			},
			{
				icon: 'mdi-home-group',
				title: 'Grupos Econômicos',
				route: { name: 'Grupos Econômicos' },
				recurso: 'grupos-economicos',
			},
			{
				icon: 'mdi-package-variant-closed',
				title: 'Estoques',
				route: { name: 'Estoques' },
				recurso: 'depositos',
			},
			{
				icon: 'mdi-finance',
				title: 'Custos',
				route: { name: 'Custos' },
				recurso: 'custos',
			},
		],
	},
	{
		icon: 'mdi-account-group',
		title: 'Clientes',
		route: {name: 'Clientes'},
		recurso: 'clientes',
		children: [
			{
						
				icon: 'mdi-account-group',
				title: 'Clientes',
				route: {name: 'Clientes'},
				recurso: 'clientes',
			},
			{
				icon: 'mdi-cake-variant',
				title: 'Tela de aniversariantes',
				route: {name: 'Tela de aniversariantes'},
				recurso: 'tela-de-aniversariantes',
			},
			{
				icon: 'mdi-basket-plus',
				title: 'Planos',
				route: {name: 'Planos'},
				recurso: 'planos',
			},
			{
				icon: 'mdi-cash-refund',
				title: 'Cashback',
				route: {name: 'Cashback'},
				recurso: 'cashback',
			},
			{
				icon: 'mdi-cash-clock',
				title: 'Campanha de Cashback',
				route: {name: 'Campanha de Cashback'},
				recurso: 'campanha-de-cashback',
			},
			{
				icon: 'mdi-cash-edit',
				title: 'Manutenção de Cashback',
				route: {name: 'Manutenção de Cashback'},
				recurso: 'manutencao-de-cashback',
			},
			{
				icon: 'mdi-account-edit',
				title: 'Alteração em Massa',
				route: {name: 'Alteração em Massa de Clientes'},
				recurso: 'alteracao-em-massa-de-clientes',
			},
		],
	},
	{
		icon: 'mdi-cash',
		title: 'Financeiro',
		children: [
			{
				icon: 'mdi-cash-multiple',
				title: 'Contas a Pagar',
				route: {name: 'Contas a Pagar'},
				recurso: 'contas-a-pagar',
			},
			{
				icon: 'mdi-cash-multiple',
				title: 'Contas a Receber',
				route: {name: 'Contas a Receber'},
				recurso: 'contas-a-receber',
			},
		],
	},
	{
		icon: 'mdi-clipboard',
		title: 'Relatórios',
		recurso: 'relatorios',
		children: [
			{
				icon: 'mdi-clipboard-list',
				title: 'Relatórios de Vendas',
				route: {name: 'Relatórios De Vendas'},
				recurso: 'relatorios',
			},
			{
				icon: 'mdi-clipboard-list',
				title: 'Relatórios de Documentos Fiscais',
				route: {name: 'Relatórios De Documentos Fiscais'},
				recurso: 'relatorios',
			},
			{
				icon: 'mdi-file-chart',
				title: 'Relatórios de Curva ABC',
				route: {name: 'Relatórios De Curva ABC'},
				recurso: 'relatorios',	
			},
		],
	},
	{
		icon:'mdi-chart-line',
		title:'Grid de Relatórios',
		route: { name: 'Grid de Relatórios' },
		recurso: 'relatorios-em-grid',
	},
	{
		icon: 'mdi-cogs',
		title: 'Gerenciar',
		route: {name: 'Minha Conta'},
		recurso: 'contas',
		children: [
			{
				icon: 'mdi-cogs',
				title: 'Conta',
				route: {name: 'Minha Conta'},
				recurso: 'contas',
			},
			{
				icon: 'mdi-account',
				title: 'Usuários',
				route: { name: 'Usuários' },
				recurso: 'usuarios',
			},
			{
				icon: 'mdi-account-key',
				title: 'Perfis',
				route: { name: 'Perfis' },
				recurso: 'perfis',
			},
			{
				icon: 'mdi-toy-brick',
				title: 'Plugins',
				route: { name: 'Plugins' },
				recurso: 'plugins',
			},
			{
				icon: 'mdi-playlist-plus',
				title: 'Movimentações',
				route: { name: 'Movimentações' },
				recurso: 'motivos-da-movimentacao',
			},
		],	
	},
]
export default itensDeMenu

export interface ItemDeMenu {
	exact?: boolean
	icon: string
	title: string
	route?: RawLocation
	children?: ItemDeMenu[]
	recurso?: TipoDeRecurso
	operacao?: TipoDeOperacao
	rotaComMenus?: boolean
}
