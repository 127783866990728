import { container } from 'inversify-props'
import { VendaServiceAdapter } from './adapter'
import { DetalhesDoPagamento, ItemDaVenda, Pagamento, TurnoDeVenda, Venda } from '@/models'
import {
	criarDevolucao,
	obterTrocoDaVenda,
	obterDescontoEmPercentual,
	unificarItensDaVendaPorProduto,
	imprimirNotaFiscal,
	obterDescontoEmPercentualDaTroca,
	emitirEComunicarDanfe,
	criarDevolucaoParaDemonstracaoOuConsignado,
} from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule, { ErroComPrefixo } from '@/store/vuex/aplicacao/AlertModule'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { imprimirCupomSemValorFiscal } from '@/shareds/pedidos/impressao-cupom-sem-valor-fiscal'
import { FindEstoqueUseCase } from '../deposito'
import { PreVendaUseCase } from './PreVendaUseCase'
import { PreVenda } from '@/models/pre-venda/PreVenda'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindModalidadeDeVendaUseCase } from './FindModalidadeDeVendaUseCase'

const findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()

type UseCaseParams = {
	venda: Venda
	detalhesDosPagamentos: DetalhesDoPagamento[]
	turnoDeVenda: TurnoDeVenda
	service?: VendaServiceAdapter
	gerarCupom: boolean
	confirmacaoDeValeTroca?: { mostrar: () => Promise<boolean> }
	imprimirCupomSemValorFiscal?: (venda: Venda, pagamentos: DetalhesDoPagamento[]) => Promise<any>
	imprimirNotaFiscal?: (idVenda: string, imprimirNfe?: boolean, isContingencia?: boolean) => Promise<any>
	emitirEComunicarDanfe?: (idVenda: string) => Promise<any>
	vendaModule?: typeof VendaModule
	findEstoqueUseCase?: FindEstoqueUseCase
	itensDevolucao?: ItemDaVenda[]
	emitirDanfe: boolean
	preVendaUseCase?: PreVendaUseCase
}

export async function FinalizarVendaUseCase({
	service = container.get<VendaServiceAdapter>('VendaServiceAdapter'),
	vendaModule = VendaModule,
	venda,
	detalhesDosPagamentos,
	gerarCupom,
	turnoDeVenda,
	findEstoqueUseCase = new FindEstoqueUseCase(),
	itensDevolucao,
	emitirDanfe,
	preVendaUseCase = new PreVendaUseCase(),
}: UseCaseParams): Promise<Venda> {
	if (!venda) throw new Error('Venda não informada')
	if (!turnoDeVenda) throw new Error('Turno de venda não informado')
	if (!venda.itens || venda.itens.length === 0) throw new Error('Venda não possui itens')

	venda.modalidadeDeVenda = venda.modalidadeDeVenda && typeof Object ? venda.modalidadeDeVenda : null
	let identificador = ''
	let vendaAEnviar: Venda | null 
	const ehUmaTroca = venda.itens.filter(item => item.quantidade > 0).length > 0 && (venda.tipoDeTransacao === 'Devolução' || venda.tipoDeTransacao === 'Outros')
	const ehUmaVendaFical = venda.notas.length > 0
	const ehUmaDemonstracaoReaberta = (venda.isDemonstracao || venda.isConsignado) && venda.isVendaReaberta
	const ehUmaVendaDeValePresente = venda?.itens.some(item => item.produto.isValePresente) ?? false
	
	
	if (venda.cliente && turnoDeVenda.id && turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.bloquearNovaVenda) {
		const podeVender = await service.podeVender(venda.cliente.cnpjOuCpf, turnoDeVenda.id)

		if (podeVender.length > 0) {
			throw Error(`Existe uma venda anterior com nota inválida (${podeVender[0]}), faça a correção dela para seguir com a venda atual.`)
		}
	}

	await validarEstoque(venda, turnoDeVenda, ehUmaVendaFical, ehUmaTroca, findEstoqueUseCase)

	try {
		identificador = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
	} catch (error: any) {
		throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
	}

	
	const gerarEstoqueNaVenda = turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.podeGerarEstoqueNaVenda || false
	if ((!gerarEstoqueNaVenda || turnoDeVenda.pontoDeVenda.criaPedidoNaVenda) && venda.tipoDeTransacao === 'Venda' && !ehUmaDemonstracaoReaberta) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens)
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if(!podeLiberarEstoque) {
			
			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.filter(item => item.quantidade != 0).map(async (itemDaVenda: any) => {
					let criticas = ''
					
					if(!itemDaVenda.autenticadoParaLiberarProdutoSemEstoque) {
						const quantidadeADevolver = (	
							itensDevolucao?.filter(i => i.id = itemDaVenda.id)
							.reduce((total, {quantidade}) => total + quantidade, 0) || 0) - itemDaVenda.quantidade
	
						await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
							itemDaVenda.id, quantidadeADevolver.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
						).catch((error) => {
							criticas = error instanceof Object ? error.response.data[0] : error
						})
					}
				
					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}

	if(ehUmaDemonstracaoReaberta) {
		return processarVendaDemonstracao(venda, turnoDeVenda, gerarCupom, emitirDanfe, detalhesDosPagamentos, service, identificador, ehUmaVendaFical, itensDevolucao)
	} else {
		if (ehUmaTroca && venda.itens.filter(item => item.quantidade > 0).length > 0) {
			vendaAEnviar = {
				...venda,
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: venda.dataHora || new Date(),
				identificador: venda.identificador,
				itens: venda.itens.filter(item => item.quantidade > 0),
				isConsignado: venda.isVendaReaberta ? false : venda.isConsignado,
				isDemonstracao: venda.isVendaReaberta ? false : venda.isDemonstracao,
				cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
				isOrcamento: !gerarCupom,
				vendaOrigem: venda.vendaOrigem,
				pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor > 0) || [],
				tipoDeTransacao: 'Venda',
				tipoNota: 'Saída',
				isTroca: true,
				desconto: obterDescontoEmPercentualDaTroca(venda),
				pedido: turnoDeVenda.pontoDeVenda.criaPedidoNaVenda ? venda.pedido : null,
				autenticandoParaLiberarVendaSemEstoque: venda.autenticandoParaLiberarVendaSemEstoque,
			}
			
		} else {
			
			vendaAEnviar = {
				...venda,
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: venda.dataHora || new Date(),
				identificador: venda.identificador,
				itens: venda.itens.filter(item => item.quantidade > 0 || item.quantidade < 0),
				isConsignado: venda.isVendaReaberta ? false : venda.isConsignado,
				isDemonstracao: venda.isVendaReaberta ? false : venda.isDemonstracao,
				cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
				isOrcamento: !gerarCupom,
				vendaOrigem: venda.vendaOrigem,
				isTroca: false,
				desconto: obterDescontoEmPercentual(venda),
				pedido: null,
				demonstracaoConvertida: venda.isDemonstracao && venda.isVendaReaberta,
				consignacaoConvertida: venda.isConsignado && venda.isVendaReaberta,
				autenticandoParaLiberarVendaSemEstoque: UserLoginStore.autenticandoParaLiberarVendaSemEstoque,
			}
		}

		const apenasDevolucao = vendaAEnviar.itens.filter(item => item.quantidade !== 0).length === 0 
			&& venda.isVendaReaberta
			&& (venda.isConsignado || venda.isDemonstracao)

		if (apenasDevolucao && itensDevolucao) {
			
			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...vendaAEnviar,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				vendaOrigem: venda.id,
				itens: itensDevolucao,
			}

			const vendaDevolucao: Venda = { 
				...criarDevolucaoParaDemonstracaoOuConsignado({
					itens: itensDevolucao,
					vendaOrigem: venda,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				isConsignado: venda.isConsignado,
				isDemonstracao: venda.isDemonstracao,
				cliente: venda.cliente,
				vendaOrigem: venda.vendaOrigem,
			}

			if (vendaDevolucao.isConsignado) {
				await service.devolucaoVendaConsignada(vendaDevolucao)
			} else {
				await service.devolucaoVendaDemonstracao(vendaDevolucao)
			}
			
			try {
				if (venda.notas.filter(notaVendaOrigem => notaVendaOrigem.cstat === '100').length > 0) {
					await emitirDanfePorVendaId(vendaDevolucao.id)
				}
			} catch (error: any) {
				const erroComPrefixo = {
					error: error,
					prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
				} as ErroComPrefixo
				AlertModule.setErrorComPrefixo(erroComPrefixo)
			}

			vendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})

			return vendaDevolucao
		}

		if((venda.isConsignado || venda.isDemonstracao) && venda.isVendaReaberta && itensDevolucao && itensDevolucao.length > 0) {
			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...vendaAEnviar,
				id: '',
				troco: obterTrocoDaVenda(venda),
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: 'Devolução',
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: venda.identificador,
				vendaOrigem: vendaAEnviar.id,
				itens: itensDevolucao,
			}

			const vendaDevolucao: Venda = {
				...criarDevolucaoParaDemonstracaoOuConsignado({
					itens: itensDevolucao,
					vendaOrigem: vendaAEnviar,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				isConsignado: venda.isConsignado,
				isDemonstracao: venda.isDemonstracao,
				cliente: venda.cliente,
				vendaOrigem: vendaAEnviar.vendaOrigem,
				desconto: null,
			}

			if (vendaDevolucao.isConsignado) {
				await service.devolucaoVendaConsignada(vendaDevolucao)
			} else {
				await service.devolucaoVendaDemonstracao(vendaDevolucao)
			}

			try {
				if (venda.notas.filter(notaVendaOrigem => notaVendaOrigem.cstat === '100').length > 0) {
					await emitirDanfePorVendaId(vendaDevolucao.id)
				}
			} catch (error: any) {
				const erroComPrefixo = {
					error: error,
					prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
				} as ErroComPrefixo
				AlertModule.setErrorComPrefixo(erroComPrefixo)
			}
		}
		
		if(ehUmaTroca) {

			let identificadorDevolucao = ''
			try {
				identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			} catch (error: any) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}

			const vendaOriginal: Venda = {
				...venda,
				id: '',
				troco: 0,
				tipoDeCliente: venda.tipoDeCliente || null,
				tipoDeTransacao: venda.tipoDeTransacao,
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				vendaOrigem: venda.vendaOrigem,
			}
 
			const vendaDevolucao: Venda = {
				...criarDevolucao({
					itens: venda.itens.filter(item => item.quantidade < 0),
					vendaOrigem: venda,
					vendaOriginal: vendaOriginal,
					...turnoDeVenda.pontoDeVenda.parametrosFiscais,
					tipoDeTransacao: 'Devolução',
					totalValorFrete: 0
				}),
				pontoDeVenda: turnoDeVenda.pontoDeVenda,
				dataHora: new Date(),
				identificador: identificadorDevolucao,
				cliente: venda.cliente,
				pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor < 0) || [],
				isTroca: true,
				vendaOrigem: vendaOriginal.vendaOrigem,
				tipoDeTransacao: venda.tipoDeTransacao,
			}
			
			await service.create(vendaDevolucao, false)
		}

		const vendaACriar: Venda = {
			...vendaAEnviar,
			cpfDoCliente: venda.cpfDoCliente,
			existeValePresente: ehUmaVendaDeValePresente,
		}
		const vendaAtualizada = await service.create(vendaACriar, emitirDanfe)

		// Nova venda
		if (!venda.identificador) vendaModule.adicionarVendaDoTurno(vendaAtualizada)
		await imprimirCupomOuDanfe(vendaAtualizada, gerarCupom, emitirDanfe, detalhesDosPagamentos)
	
		if(VendaModule.vendaAtual?.idPreVenda) {
			try {
				const preVenda: PreVenda = {
					id: VendaModule.vendaAtual?.idPreVenda,
					identificador: '',
					situacao:  'Concluído',
					dataHoraCriacao: null,
					itens: [],
					loja: null,
					cpfOuCnpjDoCliente: VendaModule.vendaAtual.cpfDoCliente,
					vendedor: null,
				}
				await preVendaUseCase.updateSituacao(preVenda)
				VendaModule.recarregaVenda
			} catch(error) {
				throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
			}
		}
		
		localStorage.setItem('ULTIMA_VENDA', JSON.stringify(vendaAtualizada));
	
		vendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
		UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

		return vendaAtualizada
	}
}

async function processarVendaDemonstracao(venda: Venda, turnoDeVenda: TurnoDeVenda, gerarCupom: boolean, 
	emitirDanfe: boolean, detalhesDosPagamentos: DetalhesDoPagamento[], service: VendaServiceAdapter,
	identificador: string, ehUmaVendaFiscal: boolean, itensDevolucao: ItemDaVenda[] | undefined): Promise<Venda> {
		
	const tipoPagamentoDemonstracao = turnoDeVenda?.tiposDePagamento.filter(tipo => tipo.formaDePagamento === 'Demonstração' || tipo.formaDePagamento === 'Consignado')
	if(tipoPagamentoDemonstracao.length === 0) {

		// eslint-disable-next-line no-constant-condition
		if(tipo => tipo.formaDePagamento === 'Demonstração'){
			throw ('Para realizar esse operação cadastre uma forma de pagamento de Demonstração')
		} else{
			throw ('Para realizar esse operação cadastre uma forma de pagamento de Consignado')
		}
	}

	const itensDaDemonstracao = venda.itens.filter(item => item.isCompra === false && item.quantidade > 0)
	
	const vendasAEnviar: Venda[] = []
	if(itensDaDemonstracao.length > 0) {
		const totalPrecoDemonstracao = itensDaDemonstracao.length > 0
			? itensDaDemonstracao.reduce((total, item) => total + item.preco*item.quantidade, 0)
			: 0

		const pagamentoDemonstracao: Pagamento[] = [{
			id: '',
			valor: totalPrecoDemonstracao,
			tipoDePagamento: tipoPagamentoDemonstracao[0],
			dataHoraPagamento: new Date(),
			qtdeDeParcelas: 0,
			status: 'Pago',
			valorDaTaxa: null,
			detalhesDoPagamentoId: null,
			link: null,
			valePresenteId: null,
		}]

		venda.autenticandoParaLiberarVendaSemEstoque = UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		
		const vendaDemonstracao: Venda = {
			...venda,
			id: '',
			identificador: '',
			troco: obterTrocoDaVenda(venda),
			tipoDeCliente: venda.tipoDeCliente || null,
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: venda.dataHora || new Date(),
			itens: itensDaDemonstracao,
			isConsignado: venda.isConsignado,
			isDemonstracao: venda.isDemonstracao,
			cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
			isOrcamento: !gerarCupom,
			vendaOrigem: venda.vendaOrigem,
			vendaIdParaCancelar: ehUmaVendaFiscal ? null : venda.id,
			pagamentos: pagamentoDemonstracao,
			tipoDeTransacao: 'Venda',
			tipoNota: 'Saída',
			isTroca: false,
			desconto: null,
			pedido:  null,
			demonstracaoConvertida: false,
			consignacaoConvertida: false,
			loja: venda.pontoDeVenda ? venda.pontoDeVenda.loja.id : "",
			cashbackAplicado: false,
			valorDeCashbackAplicado: 0,
		}

		vendasAEnviar.push(vendaDemonstracao)
	}

	const itensDaVenda = venda.itens.filter(item => item.isCompra === true && item.quantidade > 0)

	if(itensDaVenda.length > 0) {
		const params = {
			isDevolucao:false,
			isDemonstracao:true,
			isVenda:false,
		}
		let modalidade
		if(venda.pontoDeVenda){
			modalidade = await findModalidadeDeVendaUseCase.findModalidadePadrao(venda.pontoDeVenda.loja.id, params)
		}
		const vendaNormal: Venda = {
			...venda,
			id: '',
			troco: obterTrocoDaVenda(venda),
			tipoDeCliente: venda.tipoDeCliente || null,
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: venda.dataHora || new Date(),
			itens: itensDaVenda,
			isConsignado: false,
			isDemonstracao: false,
			cpfDoCliente: venda.cliente ? venda.cliente.cnpjOuCpf : '',
			isOrcamento: !gerarCupom,
			vendaOrigem: venda.vendaOrigem,
			vendaIdParaCancelar: ehUmaVendaFiscal ? null : venda.id,
			pagamentos: venda.pagamentos.filter(pagamento => pagamento.valor > 0) || [],
			tipoDeTransacao: 'Venda',
			tipoNota: 'Saída',
			modalidadeDeVenda: modalidade,
		}

		vendasAEnviar.push(vendaNormal)
	}

	if(itensDevolucao && itensDevolucao.length > 0 && ehUmaVendaFiscal) {
		let identificadorDevolucao = ''
		try {
			identificadorDevolucao = await service.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
		} catch (error: any) {
			throw Error('Falha ao finalizar a venda, tente novamente ou contate o suporte.')
		}

		const vendaOriginal: Venda = {
			...venda,
			id: '',
			troco: obterTrocoDaVenda(venda),
			tipoDeCliente: venda.tipoDeCliente || null,
			tipoDeTransacao: 'Devolução',
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: new Date(),
			identificador: venda.identificador,
			itens: itensDevolucao,
		}

		const vendaDevolucao: Venda = {
			...criarDevolucaoParaDemonstracaoOuConsignado({
				itens: itensDevolucao,
				vendaOrigem: venda,
				vendaOriginal: vendaOriginal,
				...turnoDeVenda.pontoDeVenda.parametrosFiscais,
				tipoDeTransacao: 'Devolução',
			}),
			pontoDeVenda: turnoDeVenda.pontoDeVenda,
			dataHora: new Date(),
			identificador: identificadorDevolucao,
			isConsignado: venda.isConsignado,
			isDemonstracao: venda.isDemonstracao,
			cliente: venda.cliente,
			desconto: null,
			vendaOrigem: venda.vendaOrigem,
		}
		
		if (vendaDevolucao.isConsignado) {
			await service.devolucaoVendaConsignada(vendaDevolucao)
		} else {
			await service.devolucaoVendaDemonstracao(vendaDevolucao)
		}

		try {
			if(vendasAEnviar.length > 0) {
				await emitirDanfePorVendaId(vendaDevolucao.id)
			} else {
				await imprimirCupomOuDanfe(vendaDevolucao, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)
				return vendaDevolucao
			}
		} catch (error: any) {
			const erroComPrefixo = {
				error: error,
				prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
			} as ErroComPrefixo
			AlertModule.setErrorComPrefixo(erroComPrefixo)
		}
	}

	if(vendasAEnviar.length > 0) {
		const vendaCriadas = await service.createVendas(vendasAEnviar)
		const venda = vendaCriadas.filter(venda => !venda.isDemonstracao && !venda.isConsignado)[0]
		const demonstracao = vendaCriadas.filter(venda => venda.isDemonstracao)[0]

		if(demonstracao && ehUmaVendaFiscal) {
			if(venda) {
				try {
					await emitirDanfePorVendaId(demonstracao.id)
				} catch (error: any) {
					const erroComPrefixo = {
						error: error,
						prefixo: 'Erro ao emitir danfe do retorno, verifique na tela de vendas: ',
					} as ErroComPrefixo
					AlertModule.setErrorComPrefixo(erroComPrefixo)
				}

				localStorage.setItem('ULTIMA_VENDA', JSON.stringify(venda));
				await imprimirCupomOuDanfe(venda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

				return venda
			} else {
				await imprimirCupomOuDanfe(demonstracao, gerarCupom, emitirDanfe, detalhesDosPagamentos)
				localStorage.setItem('ULTIMA_VENDA', JSON.stringify(demonstracao));
				VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
				UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

				return demonstracao
			}
			
		} else if(venda && vendaCriadas.length > 1) {
			await imprimirCupomOuDanfe(venda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
			localStorage.setItem('ULTIMA_VENDA', JSON.stringify(venda));
			VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

			return venda
		} else {
			const ImprimirVenda = vendaCriadas[0]
			await imprimirCupomOuDanfe(ImprimirVenda, gerarCupom, emitirDanfe, detalhesDosPagamentos)
			localStorage.setItem('ULTIMA_VENDA', JSON.stringify(ImprimirVenda));
			VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

			return ImprimirVenda
		}
	} else {
		const vendaCancelada = await service.cancelar(venda.id)
		VendaModule.iniciarNovaVenda({identificador, novaVendaReaberta: false})
		UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(false)

		return vendaCancelada
	}
}

async function imprimirCupomOuDanfe(vendaAtualizada: Venda, gerarCupom: boolean, emitirDanfe: boolean, detalhesDosPagamentos: DetalhesDoPagamento[]) {
	if (gerarCupom && emitirDanfe) {
		try {
			await imprimirNotaFiscal(vendaAtualizada.id, true);
		} catch (error: any) {
			AlertModule.setError(error);
		}
	} else {
		if (gerarCupom && (vendaAtualizada.pontoDeVenda?.podeGerarNFCE || vendaAtualizada.pontoDeVenda?.podeGerarNFE)) {
			try {
				await imprimirNotaFiscal(vendaAtualizada.id);
			} catch (error: any) {
				AlertModule.setError(error);
			}
		}

		PluginModule.emit("finalizarVenda", { venda: vendaAtualizada });

		if (vendaAtualizada.tipoDeTransacao === 'Devolução' || vendaAtualizada.tipoDeTransacao === 'Outros') {
			AlertModule.setSuccess("Devolução realizada com sucesso");
		} else if (vendaAtualizada.pontoDeVenda?.imprimeReciboNoOrcamento && !gerarCupom) {
			const vendaComCliente = {
				...vendaAtualizada,
				cliente: vendaAtualizada.cliente,
			};
			await imprimirCupomSemValorFiscal(vendaComCliente, detalhesDosPagamentos);
		}
	}
}

async function emitirDanfePorVendaId(vendaId: string) {
	await emitirEComunicarDanfe(vendaId)
}

async function validarEstoque(venda: Venda, turnoDeVenda: TurnoDeVenda, ehUmaDemonstracaoSemNotaEReaberta: boolean,
	ehUmaTroca: boolean, findEstoqueUseCase: FindEstoqueUseCase) {
	const ehUmaDemonstracao = venda.isDemonstracao || venda.isConsignado

	const gerarEstoqueNaVenda = turnoDeVenda.pontoDeVenda.loja.configuracaoDaLoja?.podeGerarEstoqueNaVenda ||
	UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque

	if ((!gerarEstoqueNaVenda || turnoDeVenda.pontoDeVenda.criaPedidoNaVenda) && venda.tipoDeTransacao === 'Venda' 
			&& !ehUmaDemonstracaoSemNotaEReaberta && !ehUmaDemonstracao) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens)
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if(!podeLiberarEstoque) {
			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.map(async (itemDaVenda: any) => {
					let criticas = ''
					await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.id, itemDaVenda.quantidade.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
					).catch((error) => {
						criticas = error instanceof Object ? error.response.data[0] : error
					})
					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}

	//valida estoque somente dos itens positivos(itens da venda)
	if (ehUmaTroca && !gerarEstoqueNaVenda) {
		const itensUnificados = unificarItensDaVendaPorProduto(venda.itens.filter(item => item.quantidade > 0))
		const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque') || UserLoginStore.autenticandoParaLiberarVendaSemEstoque
		let projecoesPorItemDaVenda
		if(!podeLiberarEstoque) {
			projecoesPorItemDaVenda = await Promise.all(
				itensUnificados.map(async (itemDaVenda: any) => {
					let criticas = ''
					await findEstoqueUseCase.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.id, itemDaVenda.quantidade.toString(), turnoDeVenda?.pontoDeVenda.loja.id || '', itemDaVenda.autenticadoParaLiberarProdutoSemEstoque,
					).catch((error) => {
						criticas = error instanceof Object ? error.response.data[0] : error
					})
					return criticas
				}),
			)
			projecoesPorItemDaVenda = projecoesPorItemDaVenda.filter(value => value !== '')
		}
		const criticasNormalizadas = projecoesPorItemDaVenda
			? projecoesPorItemDaVenda.join('<br>')
			: undefined
		if (criticasNormalizadas) {
			throw criticasNormalizadas
		}
	}
}