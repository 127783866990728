import { AniversariantesListagem, Cliente, FormCliente, FormLoja, Page, TipoDePagamento, TipoDePagamentoBloqueadoCliente } from '@/models'
import request from '@/common/request'
import { AxiosRequestConfig } from 'axios'

const PATH = '/clientes'

export const create = async (data: FormCliente): Promise<Cliente> =>
	await request.post(`${PATH}`, data)

export const update = async (data: FormCliente): Promise<Cliente> =>
	await request.put(`${PATH}/${data.id}`, data)

export const vincularCliente = async (clienteId: string, lojas: FormLoja[]): Promise<void> =>
	request.post(`${PATH}/${clienteId}/vincular`, lojas)

export const existeVinculoDeClinteELoja = async (clienteId: string, lojaId: string): Promise<boolean> =>
	request.get(`${PATH}/${lojaId}/existe-vinculo/${clienteId}`)

export const get = async (id: string, params?: any, config?: AxiosRequestConfig): Promise<Cliente> =>
	await request.get(`${PATH}/${id}`, {
		params,
		...config,
	})

export const find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
	await request.get(`${PATH}`, {
		params,
		...config,
	})

export const findClienteComCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
	await request.get(`${PATH}/cashback`, {
		params,
		...config,
	})

export const findClientesVinculadosComLoja = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
	await request.get(`${PATH}/loja`, {
		params,
		...config,
	})

export const importaItens = async(urlDoArquivoCsv: string, arquivoContemCabecalho: boolean ): Promise<any> =>
{
	await request.post(`${PATH}/importa-cliente?arquivoContemCabecalho=${arquivoContemCabecalho}&urlDoArquivoCsv=${urlDoArquivoCsv}`)
}


export const findAniversariantes = async (params?: any, config?: AxiosRequestConfig): Promise<Page<AniversariantesListagem>> =>
	await request.get(`${PATH}/aniversariantes`, {
		params,
		...config,
	})

export const findQtdCuponsPorCpf = async (params?: any): Promise<number> =>
	await request.get(`${PATH}/quantidade-de-cupom`, {
		params,
	})

export const alterarClienteEmMassaByFiltro = async (params?: any, config?: AxiosRequestConfig): Promise<Page<any>> =>
	request.patch(`${PATH}/alteracao-em-massa`, {
		params,
		...config,
	})

export const listarTiposDePagamentoBloqueadosCliente = async (cliente: any, lojaId: any, config?: AxiosRequestConfig): Promise<TipoDePagamento[]> =>
	request.get(`${PATH}/tipos-de-pagamento-bloqueados`, {
		params: { cliente, lojaId },
		...config,
	});

export const findTiposDePagamentosDoCliente = async (clienteId: any, params?: any, config?: AxiosRequestConfig): Promise<Page<TipoDePagamentoBloqueadoCliente>> =>
	request.get(`${PATH}/tipos-pagamento-cliente`, {
		params: { clienteId, params },
		...config,
	});

export const deleteBloqueioDePagamentoByClienteIdAndBloqueioId = async (bloqueioId: string, clienteId: string): Promise<void> =>
	request.get(`${PATH}/deleta-bloqueio-pagamento`, {
		params: {bloqueioId, clienteId},
	});

export const alterarBloqueioDeTipoDePagamentoClienteByFiltro = async (params?: any, config?: AxiosRequestConfig): Promise<Page<any>> =>
	request.patch(`${PATH}/alteracao-bloqueio-pagamento`, {
		params,
		...config,
	})
