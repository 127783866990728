import { Inject } from "inversify-props"
import type { EmailServiceAdapter } from "./adapter"

export class EmailUseCase {
	@Inject('EmailServiceAdapter')
	private emailService!: EmailServiceAdapter
	
	async enviarValesPorEmail(vendaId: string, email: string): Promise<void> {
		await this.emailService.enviarValesPorEmail(vendaId, email)
	}
}