import { ClienteServiceAdapter } from '@/usecases'
import { create, update, find, get, findClienteComCashback, vincularCliente, existeVinculoDeClinteELoja, findClientesVinculadosComLoja, 
	findAniversariantes, findQtdCuponsPorCpf } from '@/api/cliente'
import { FormCliente, FormLoja, FormVoucher, Page, Voucher, TipoDePagamento, TipoDePagamentoBloqueadoCliente } from '@/models'
import { AxiosRequestConfig } from 'axios'
import request from '@/common/request'

const PATH = '/clientes'

export class ClienteService implements ClienteServiceAdapter {
	create = async (cliente: FormCliente) => await create(cliente)
	update = async (cliente: FormCliente) => await update(cliente)
	get = async (id: string, params?: any, config?: AxiosRequestConfig) => await get(id, params, config)
	find = async (params?: any, config?: AxiosRequestConfig) => await find(params, config)
	findClienteComCashback = async (params?: any, config?: AxiosRequestConfig) => await findClienteComCashback(params, config)
	vincularClienteComLoja = async (clienteId: string, lojas: FormLoja[]) => await vincularCliente(clienteId, lojas)
	existeVinculoDeClinteELoja = async (clienteId: string, lojaId: string) => await existeVinculoDeClinteELoja(clienteId, lojaId)
	findClientesVinculadosComLoja = async (params?: any, config?: AxiosRequestConfig) => await findClientesVinculadosComLoja(params, config)
	findAniversariantes = async (params?: any, config?: AxiosRequestConfig) => await findAniversariantes(params, config)
	findQtdCuponsPorCpf = async (params?: any) => await findQtdCuponsPorCpf(params)

	async getVouchers(clienteId: string, params?: any, config?: AxiosRequestConfig): Promise<Page<Voucher>> {
		return request.get(`${PATH}/${clienteId}/vouchers`, {
			params,
			...config,
		})
	}

	async findTotalizadorVouchersByClienteId(clienteId: string, params?: any, config?: AxiosRequestConfig): Promise<number> {
		return request.get(`${PATH}/${clienteId}/total-voucher`, {
			params,
			...config,
		})
	}

	async addVoucher(voucher: FormVoucher): Promise<Voucher> {
		return request.post(`${PATH}/${voucher.clienteId}/vouchers`, voucher)
	}

	async importaItens(urlDoArquivoCsv: string, arquivoContemCabecalho: boolean): Promise<Response> {
		return request.post(`${PATH}/importa-cliente?arquivoContemCabecalho=${arquivoContemCabecalho}&urlDoArquivoCsv=${urlDoArquivoCsv}`)
	}

	async alterarClienteEmMassaByFiltro(clientes: string[], filtroLoja: string, bloquearTiposDePagamento: boolean, liberarTiposDePagamento: boolean, tiposDePagamentoSelecionados: string[], usarTodosOsClientesFiltradosDaLoja: boolean): Promise<any[]>{
		return request.patch(`${PATH}/alteracao-em-massa`, {
			clientes,
			filtroLoja,
			bloquearTiposDePagamento,
			liberarTiposDePagamento,
			tiposDePagamentoSelecionados,
			usarTodosOsClientesFiltradosDaLoja,
		})
	}

	listarTiposDePagamentoBloqueadosCliente = async (cliente: any, lojaId: any, config?: AxiosRequestConfig): Promise<TipoDePagamento[]> => {
		return request.get(`${PATH}/tipos-de-pagamento-bloqueados`, {
			params: { cliente, lojaId },
			...config,
		});
	}

	findTiposDePagamentosDoCliente = async (clienteId: any, params?: any, config?: AxiosRequestConfig): Promise<Page<TipoDePagamentoBloqueadoCliente>> => {
		return request.get(`${PATH}/tipos-pagamento-cliente`, {
			params: { clienteId, params },
			...config,
		});
	}

	deleteBloqueioDePagamentoByClienteIdAndBloqueioId = async (bloqueioId: string, clienteId: string): Promise<void> =>
		request.get(`${PATH}/deleta-bloqueio-pagamento`, {
			params: {bloqueioId, clienteId},
		});

	async alterarBloqueioDeTipoDePagamentoClienteByFiltro(clienteId: string, lojaId: string, bloquearTiposDePagamento: boolean, liberarTiposDePagamento: boolean, tiposDePagamentoSelecionados: string[]): Promise<any[]>{
		return request.patch(`${PATH}/alteracao-bloqueio-pagamento`, {
			clienteId,
			lojaId,
			bloquearTiposDePagamento,
			liberarTiposDePagamento,
			tiposDePagamentoSelecionados,
		})
	}
}
