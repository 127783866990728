import { Page } from "@/models";
import { BaixaDePagamento } from "@/models/financeiro/BaixaDePagamento";
import { Inject } from 'inversify-props';
import type { BaixaDePagamentoServiceAdapter } from "./adapter/BaixaDePagamentoServiceAdapter";
import { AxiosRequestConfig } from "axios";

export class FindBaixaDePagamentoUseCase {
	@Inject("BaixaDePagamentoServiceAdapter")
	private baixaDePagamentoServiceAdapter!: BaixaDePagamentoServiceAdapter


	findByTituloId = async (params?: any, config?: AxiosRequestConfig): Promise<Page<BaixaDePagamento>> =>
		await this.baixaDePagamentoServiceAdapter.find(params, config)

	imprimirBaixaDePagamento = async(baixaDePagamento: BaixaDePagamento): Promise<any> =>
		await this.baixaDePagamentoServiceAdapter.imprimirBaixaDePagamento(baixaDePagamento)
}